import React from "react";
import "./Data.css";
const Data = () => {
  return (
    <div className="data">
      <div className="container">
        <div className="content">
          <h2>
            <span>Data</span>Recovery
          </h2>
          <p>
          Data security is the practice of protecting digital information from unauthorized access, corruption, or theft throughout its entire lifecycle. It’s a concept that encompasses every aspect of information security from the physical security of hardware and storage devices to administrative and access controls, as well as the logical security of software applications. 

          </p>
          <button>Sign Up</button>
        </div>
        
      </div>
      
    </div>
  );
};

export default Data;
