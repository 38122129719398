import React from "react";
import "./Recovery.css";

const Recovery = () => {
  return (
    <div className="recovery">
      <div className="container">
        <div className="content">
          <h2>
            <span>Data</span>Recovery
          </h2>
          <p>
            Millions of businesses find their data under the threat of malicious
            breaches and viruses every year. One of the most troubling of these
            cybercrimes is ransomware attacks. Many businesses find themselves
            powerless against ransomware attacks as they disrupt data management
            and terminate businesses entirely. To keep your data safe against
            malicious bugs, you must stay on top of ransomware attacks and
            everything they can do. Let us look at how you can recover
            confidential data from these threats and protect your business
            against cybercrimes.
          </p>
          <div>
            <button>Learn More</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recovery;
