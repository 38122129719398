import React from 'react'

import './Body.css'

const Body = () => {
  return (
    <>
    <div className='hero'>
        <div className='container'>
            <div className='content'>
                <h1>Data</h1>
                <h1 className='blue'>Secured</h1>
                <h1>Protection</h1>
                <div><button>learn More</button></div>
            </div>
        </div>
      
    </div>
    </>
  )
}

export default Body
