import React from 'react'
import Navbar from '../navbar/Navbar.js'
import Contact from '../contact/Contact.js'
import Footer from '../footer/Footer'

const ContactPage = () => {
  return (
    <>
    <Navbar />
    <Contact />
    <Footer />
    </>
  )
}

export default ContactPage
