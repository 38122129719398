import React from 'react';
import Navbar from '../navbar/Navbar.js';
import Cloud from '../clouds/Cloud.js';
import Footer from '../footer/Footer';

const CloudPage = () => {
  return (
    <>
      <Navbar />
      <Cloud />
      <Footer />
    </>
  );
};

export default CloudPage;
