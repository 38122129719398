import React from 'react';
import Navbar from './components/navbar/Navbar.js';
import Body from './components/body/Body';
import Data from './components/data/Data'
import Cloud from './components/clouds/Cloud'
import Footer from './components/footer/Footer'
function App() {
  return (
    <>
  <Navbar />
   <Body />
   <Data />
   <Cloud />
  <Footer />
  </>
  );
}

export default App;
